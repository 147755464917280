import { useState } from 'react';
import logo from '../assets/img/logo.svg';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal';

function Header() {
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);

  return (
    <>
      <div>
        <div>
          <nav
            className="navbar navbar-light navbar-expand-md fixed-top"
            style={{ position: 'absolute' }}
          >
            <div className="container-fluid">
              <a className="logo-link" href="/">
                <img className="logo" src={logo} alt="RaceUp" />
              </a>
              <ul className="navbar-nav d-flex d-sm-flex flex-row flex-shrink-0 justify-content-center align-items-center ms-auto align-items-sm-center">
                <li className="nav-item" id="login">
                  <div
                    onClick={() => setSignInModalOpen(true)}
                    className="nav-link active loginLink"
                  >
                    Login
                  </div>
                </li>
                <li className="nav-item" id="signUp">
                  <div
                    className="nav-link"
                    style={{
                      color: '#1D2635',
                      fontFamily: '"Inter"',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '18px',
                      lineHeight: '146.3%',
                      alignItems: 'center',
                      textAlign: 'center',
                      letterSpacing: '0.015em',
                      flex: 'none',
                      order: 0,
                      flexGrow: 0,
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSignUpModalOpen(true)}
                  >
                    Sign up
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="header-line" />
      </div>

      <SignInModal
        open={signInModalOpen}
        setOpenModal={setSignInModalOpen}
        setOpenOther={setSignUpModalOpen}
      />
      <SignUpModal
        open={signUpModalOpen}
        setOpenModal={setSignUpModalOpen}
        setOpenOther={setSignInModalOpen}
      />
    </>
  );
}

export default Header;
