import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import eyeIcon from '../assets/img/showOn.svg';
import eyeCrossIcon from '../assets/img/showOff.svg';
import { signUp } from '../utils/api';
import { emailRegExp } from '../utils/const';
import toast from 'react-hot-toast';

function ErrorField({ message }) {
  return <span className="form-control__error">{message}</span>;
}

function SignUpModal({ open, setOpenModal, setOpenOther }) {
  const initialForm = {
    company_name: '',
    email: '',
    full_name: '',
    password: '',
    password_confirmation: '',
    telegram: '',
    terms_accepted: false,
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const [confirmationPasswordShown, setConfirmationPasswordShown] =
    useState(false);

  const [isLoading, setLoading] = useState(false);
  const [formError, setErrors] = useState({});

  const [form, setForm] = useState(initialForm);
  const {
    company_name,
    email,
    full_name,
    password,
    password_confirmation,
    telegram,
    terms_accepted,
  } = form;

  const handleChange = (object) => {
    setForm({
      ...form,
      ...object,
    });
  };

  const showError = (object) => {
    setErrors({
      ...formError,
      ...object,
    });
  };

  const getServerErrors = (errors) => {
    const data = Object.entries(errors || {})
      ?.map((i) => ({
        key: i[0],
        value: i[1]?.length > 0 ? i[1][0] : '',
      }))
      .reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      );

    setErrors(data);
  };

  const handleClose = () => {
    setErrors({});
    setForm(initialForm);
    setOpenModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validEmail = emailRegExp.test(email);
    setErrors({});

    if (
      validEmail &&
      password &&
      password_confirmation &&
      password === password_confirmation &&
      terms_accepted
    ) {
      setLoading(true);

      signUp({
        partner_user: {
          company_name,
          email,
          full_name,
          password,
          password_confirmation,
          telegram,
          terms_accepted,
        },
      })
        .then(() => {
          toast.success('Registered successfully! We will let you know when we approve your account.');
          handleClose();
        })
        .catch((e) => {
          if (Object.keys(e?.response?.data?.errors || {}).length > 0) {
            getServerErrors(e?.response?.data?.errors);
          } else {
            toast.error('Something went wrong. Try again later');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      showError({
        email: validEmail ? '' : 'Invalid email',
        password: password === password_confirmation ? '' : 'Password mismatch',
        password_confirmation:
          password === password_confirmation ? '' : 'Password mismatch',
        terms_accepted: terms_accepted ? '' : 'Must be accepted',
      });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
      show={open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <div className="mx-auto text-center">
          <div className="sign-form__title sign-form__title--secondary">
            Registration
          </div>
          <div className="sign-form__subtitle mb-4">Your adventure begins</div>
        </div>
      </Modal.Header>

      <Modal.Body className="sign-form">
        <form onSubmit={handleSubmit}>
          <Form.Control
            className="mt-2 mb-0"
            placeholder="Company name*"
            value={company_name}
            type="text"
            name="company_name"
            onChange={(e) => handleChange({ company_name: e.target.value })}
          />
          <ErrorField message={formError.company_name} />
          <Form.Control
            className="mt-2 mb-0"
            placeholder="Email*"
            value={email}
            type="text"
            name="email"
            onChange={(e) => handleChange({ email: e.target.value })}
          />
          <ErrorField message={formError.email} />
          <Form.Control
            className="mt-2 mb-0"
            placeholder="Full name*"
            value={full_name}
            type="text"
            name="full_name"
            onChange={(e) => handleChange({ full_name: e.target.value })}
          />
          <ErrorField message={formError.full_name} />
          <div style={{ position: 'relative' }}>
            <Form.Control
              className="mt-2 mb-0"
              autoComplete="off"
              placeholder="Password*"
              value={password}
              type={passwordShown ? 'text' : 'password'}
              name="password"
              onChange={(e) => handleChange({ password: e.target.value })}
            />

            <img
              className="input-icon"
              src={passwordShown ? eyeCrossIcon : eyeIcon}
              alt="eye"
              onClick={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <ErrorField message={formError.password} />
          <div style={{ position: 'relative' }}>
            <Form.Control
              className="mt-2 mb-0"
              autoComplete="off"
              placeholder="Password confirmation*"
              value={password_confirmation}
              type={confirmationPasswordShown ? 'text' : 'password'}
              name="password_confirmation"
              onChange={(e) =>
                handleChange({ password_confirmation: e.target.value })
              }
            />

            <img
              className="input-icon"
              src={confirmationPasswordShown ? eyeCrossIcon : eyeIcon}
              alt="eye"
              onClick={() =>
                setConfirmationPasswordShown(!confirmationPasswordShown)
              }
            />
          </div>
          <ErrorField message={formError.password_confirmation} />
          <Form.Control
            className="mt-2 mb-3"
            placeholder="@telegram"
            value={telegram}
            type="text"
            name="telegram"
            onChange={(e) => handleChange({ telegram: e.target.value })}
          />
          <p className="hint mb-1">
            <input
              className="ui-checkbox ui-checkbox-secondary"
              type="checkbox"
              name="accept"
              checked={terms_accepted || false}
              onChange={() => {
                handleChange({ terms_accepted: !terms_accepted });
              }}
              value={terms_accepted}
            />{' '}
            I am 18 years old and I accept the{' '}
            <a
              className="sign-form__link sign-form__link--underline"
              target="_blank"
              href="/privacy-policy"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className="sign-form__link sign-form__link--underline"
              target="_blank"
              href="/terms-and-conditions"
            >
              Terms and Conditions
            </a>
          </p>
          <ErrorField message={formError.terms_accepted} />

          <div className="text-center mt-4">
            <Button
              className="mt-2 mb-3"
              autoComplete="off"
              type="submit"
              disabled={isLoading}
              variant="secondary"
              style={{ width: '100%' }}
            >
              Sign up
            </Button>
            <p className="hint text-center mx-auto mb-0">
              Already have an account?{' '}
              <span
                className="sign-form__link"
                onClick={() => {
                  setErrors({});
                  setForm(initialForm);
                  setOpenModal(false);
                  setOpenOther(true);
                }}
              >
                Login now
                <span className="caret ms-1" />
              </span>
            </p>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SignUpModal;
