import * as React from 'react';
import {
  Outlet,
  Link,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import { Toaster } from 'react-hot-toast';

import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import CookieAlert from './components/CookieAlert';
import './assets/style/App.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Content />,
      },
      {
        path: 'privacy-policy',
        lazy: () => import('./components/PrivacyPolicy'),
      },
      {
        path: 'terms-and-conditions',
        lazy: () => import('./components/TermsAndConditions'),
      },
    ],
  },
  {
    path: '*',
    element: <NoMatch />,
  },
]);

function Loader() {
  return (
    <div className="spinner-wrap">
      <Spinner animation="grow" variant="primary" />
    </div>
  );
}

export default function App() {
  return <RouterProvider router={router} fallbackElement={<Loader />} />;
}

function Layout() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      <CookieAlert />
      <Toaster position="top-right" />
    </div>
  );
}

function NoMatch() {
  return (
    <div className="page-404">
      <h2>404</h2>
      <p>
        <Link to="/" className="document-page__link">
          Go to the home page
        </Link>
      </p>
    </div>
  );
}
