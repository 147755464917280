import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import eyeIcon from '../assets/img/showOn.svg';
import eyeCrossIcon from '../assets/img/showOff.svg';
import { signIn } from '../utils/api';
import toast from 'react-hot-toast';

function ErrorField({ message }) {
  return <div className="form-control__error mb-3">{message}</div>;
}

function SignInModal({ open, setOpenModal, setOpenOther }) {
  const initialForm = {
    username: '',
    password: '',
  };

  const [isLoading, setLoading] = useState(false);
  const [formError, showError] = useState('');

  const [passwordShown, setPasswordShown] = useState(false);
  const [form, setForm] = useState(initialForm);
  const { username, password } = form;

  const handleChange = (object) => {
    setForm({
      ...form,
      ...object,
    });
  };

  const handleClose = () => {
    showError('');
    setForm(initialForm);
    setOpenModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showError('');

    if (username && password) {
      setLoading(true);

      signIn({
        partner_user: {
          email: username,
          password,
        },
      })
        .then(() => {
          setLoading(false);
          handleClose();
          window.location.replace(
            'https://backend.raceupcasinoaffiliate.com/partner/dashboard'
          );
        })
        .catch((e) => {
          if (e?.response?.data?.error) {
            showError(e.response.data.error);
          } else {
            toast.error('Something went wrong. Try again later');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      showError('Enter email and password.');
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
      show={open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <div className="mx-auto text-center">
          <div className="sign-form__title sign-form__title--primary">
            Login
          </div>
          <div className="sign-form__subtitle mb-4">Welcome back!</div>
        </div>
      </Modal.Header>

      <Modal.Body className="sign-form">
        <form onSubmit={handleSubmit}>
          <Form.Control
            className="mb-3"
            placeholder="Email*"
            value={username}
            type="text"
            name="username"
            onChange={(e) => handleChange({ username: e.target.value })}
          />

          <div style={{ position: 'relative' }}>
            <Form.Control
              className="mb-1"
              autoComplete="off"
              placeholder="Password*"
              value={password}
              type={passwordShown ? 'text' : 'password'}
              name="password"
              onChange={(e) => handleChange({ password: e.target.value })}
            />

            <img
              className="input-icon"
              src={passwordShown ? eyeCrossIcon : eyeIcon}
              alt="eye"
              onClick={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <ErrorField message={formError} />

          <a
            className="sign-form__link sign-form__link--underline"
            href="https://backend.raceupcasinoaffiliate.com/partner/password/new"
          >
            Forgot your password?
          </a>
          <div className="text-center mt-4">
            <Button
              className="mb-3"
              autoComplete="off"
              type="submit"
              disabled={isLoading}
              variant="primary"
              style={{ width: '100%' }}
            >
              Login
            </Button>
            <p className="hint text-center mx-auto mb-0">
              Don’t have an account?{' '}
              <span
                className="sign-form__link"
                onClick={() => {
                  // setErrors({});
                  setForm(initialForm);
                  setOpenModal(false);
                  setOpenOther(true);
                }}
              >
                Register now
                <span className="caret ms-1" />
              </span>
            </p>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SignInModal;
