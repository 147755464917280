import socialStar from '../assets/img/social-star.svg';
import telegram from '../assets/img/telegram.svg';
import email from '../assets/img/email.svg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer" id="bottomInfo">
      <div className="mx-3 footer-item">
        <img src={socialStar} style={{ letterSpacing: 0 }} alt="star" />
        <a className="footer__link" href="https://raceupcasino.com">
          raceupcasino.com
        </a>
      </div>
      <div className="mx-3 footer-item">
        <img src={telegram} style={{ letterSpacing: 0 }} alt="telegram" />
        <a className="footer__link" href="https://t.me/affiliates_raceupcasino">
          @affiliates_raceupcasino
        </a>
      </div>
      <div className="mx-3 footer-item">
        <img src={email} style={{ letterSpacing: 0 }} alt="email" />
        <a className="footer__link" href="mailto:affiliates@raceupcasino.com">
          affiliates@raceupcasino.com
        </a>
      </div>

      <div className='footer__container-end me-3'>
        <div className="mx-3 footer-item">
          <Link to="terms-and-conditions" className="footer__link">
            Terms and Conditions
          </Link>
        </div>
        <div className="mx-3 footer-item">
          <Link to="privacy-policy" className="footer__link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
