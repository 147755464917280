import { useEffect, useState } from 'react';
import { setCookie, getCookie } from '../utils/const';

export default function CookieAlert() {
  const cookiesAccepted = getCookie('cookies_accepted');
  const [shownAlert, setAlert] = useState(!cookiesAccepted);

  useEffect(() => {
    if (cookiesAccepted === 'true') {
      setAlert(false);
    } else {
      setAlert(true);
    }
  }, [shownAlert]);

  const handleClick = () => {
    setAlert(false);
    setCookie('cookies_accepted', true);
  };

  return (
    shownAlert && (
      <div className="notification-cookies-policy">
        <div className="notification-cookies-policy__wrap">
          <h4 className="notification-cookies-policy__title mb-0">
            &#127850; We use cookies to improve your experience. By using our
            website you are accepting our{' '}
            <a
              className="notification-cookies-policy__link"
              variant="primary"
              href="/privacy-policy"
            >
              Cookie Policy
            </a>
            .
          </h4>
          <button
            className="notification-cookies-policy__button"
            onClick={handleClick}
          >
            Accept
          </button>
        </div>
      </div>
    )
  );
}
