import axios from 'axios';

export const API_NODE_URL =
  'https://backend.raceupcasinoaffiliate.com/api/client/partner';

export const signUp = (data) => {
  return axios({
    method: 'post',
    baseURL: API_NODE_URL,
    url: '',
    headers: { 'content-type': 'application/json' },
    data,
  });
};

export const signIn = (data) => {
  return axios({
    method: 'post',
    baseURL: API_NODE_URL,
    url: 'sign_in',
    headers: { 'content-type': 'application/json' },
    data,
  });
};
