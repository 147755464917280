import { useState } from 'react';
import SignUpModal from './SignUpModal';

import percent from '../assets/img/percent.svg';
import dollar from '../assets/img/dollar.svg';
import star from '../assets/img/star.svg';
import cloud from '../assets/img/cloud.svg';
import three from '../assets/img/three.svg';
import profile from '../assets/img/profile.svg';

function Content() {
  const [SignUpModalOpen, setSignUpModalOpen] = useState(false);

  return (
    <>
      <SignUpModal open={SignUpModalOpen} setOpenModal={setSignUpModalOpen} />

      <div className="row">
        <div className="col">
          <div className="main-wrap">
            <div id="topBanner">
              <div className="col">
                <div className="container">
                  <h1
                    className="mx-1"
                    style={{
                      marginBottom: '12px',
                      fontFamily: '"Inter"',
                      fontStyle: 'normal',
                      fontWeight: 900,
                      lineHeight: '140.3%',
                      color: '#FDFDFD',
                    }}
                  >
                    <strong>Gambling offer&nbsp;#&nbsp;1</strong>
                  </h1>
                  <div className="text-center">
                    <p
                      className="mb-1 mx-auto main-description"
                      style={{
                        fontFamily: '"Inter"',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 'min(max(15px, 8vw), 36px)',
                        lineHeight: '146.28%',
                        textAlign: 'center',
                        color: '#ffffff',
                        minWidth: '220px',
                      }}
                    >
                      Affiliate program RaceUp offers
                    </p>
                    <p
                      className="mb-5 mx-auto main-description main-description--end"
                      style={{
                        fontFamily: '"Inter"',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 'min(max(15px, 8vw), 36px)',
                        lineHeight: '146.28%',
                        textAlign: 'center',
                        color: '#ffffff',
                        minWidth: '220px',
                      }}
                    >
                      <strong>up to 60% profit</strong> from the player for life
                    </p>
                  </div>
                </div>
                <div className="rowElements mb-5">
                  <div
                    className="mx-2"
                    style={{ display: '-webkit-inline-box' }}
                  >
                    <img
                      src={percent}
                      style={{ letterSpacing: '0px' }}
                      alt="percent"
                    />
                    <p
                      className="parag parag--first"
                      style={{
                        color: 'rgb(255,255,255)',
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginLeft: '10px',
                        fontFamily: '"Inter"',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '138.28%',
                      }}
                    >
                      Up to 60% revshare{' '}
                    </p>
                  </div>
                  <div
                    className="mx-2"
                    style={{ display: '-webkit-inline-box' }}
                  >
                    <img
                      src={dollar}
                      style={{ letterSpacing: '0px' }}
                      alt="dollar"
                    />
                    <p
                      className="parag parag--center"
                      style={{
                        color: 'rgb(255,255,255)',
                        maxWidth: '130px',
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginLeft: '10px',
                        fontFamily: '"Inter"',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '138.28%',
                      }}
                    >
                      Daily Payments
                    </p>
                  </div>
                  <div
                    className="mx-2"
                    style={{ display: '-webkit-inline-box' }}
                  >
                    <img
                      src={star}
                      style={{ letterSpacing: '0px' }}
                      alt="star"
                    />
                    <p
                      className="parag parag--end"
                      style={{
                        color: 'rgb(255,255,255)',
                        maxWidth: '130px',
                        textAlign: 'left',
                        marginBottom: '0px',
                        marginLeft: '10px',
                        fontFamily: '"Inter"',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '138.28%',
                        marginRight: '0px',
                        paddingRight: 0,
                      }}
                    >
                      Our own product. The best quality.
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary mb-2 mx-2"
                id="start"
                type="button"
                onClick={() => setSignUpModalOpen(true)}
              >
                Become a partner
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="about-us" className="about-wrap">
        <div className="d-lg-flex justify-content-lg-center align-items-lg-center">
          <div className="col d-sm-flex d-md-flex d-xxl-flex justify-content-sm-center justify-content-md-center align-items-md-center justify-content-xxl-center align-items-xxl-center">
            <div
              className="container"
              style={{ marginRight: '0px', marginLeft: '0px' }}
            >
              <h2
                className="mb-4 text-center"
                style={{
                  color: '#FDFDFD',
                  fontFamily: '"Inter"',
                  fontStyle: 'normal',
                  fontWeight: 900,
                  lineHeight: '58px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <strong>About Us</strong>
              </h2>
              <div
                className="text-center d-lg-flex justify-content-lg-center align-items-lg-center"
                style={{ textAlign: 'center' }}
              >
                <p
                  className="about-us-parag"
                  style={{
                    textAlign: 'center',
                    color: '#ffffff',
                    maxWidth: '820px',
                    minWidth: '220px',
                    fontFamily: '"Inter"',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '146%',
                    marginBottom: '16px',
                  }}
                >
                  We are advanced specialists in the Gambling field with many
                  years of experience and we know exactly about all the details
                  with traffic monetization. By cooperating with us, you can be
                  100% sure of the professionalism and competence of our team!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rowElements feature-wrap mb-4">
          <div className="mx-2 feature-block">
            <img src={cloud} style={{ letterSpacing: '0px' }} alt="cloud" />
            <p
              style={{
                color: 'rgb(255,255,255)',
                textAlign: 'left',
                marginBottom: '0px',
                marginLeft: '10px',
                fontFamily: '"Inter"',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '138.28%',
              }}
            >
              Detailed statistics
            </p>
          </div>
          <div className="mx-2 feature-block">
            <img src={three} style={{ letterSpacing: '0px' }} alt="three" />
            <p
              style={{
                color: 'rgb(255,255,255)',
                textAlign: 'left',
                marginBottom: '0px',
                marginLeft: '10px',
                fontFamily: '"Inter"',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '138.28%',
              }}
            >
              Customized promo-materials
            </p>
          </div>
          <div className="mx-2 feature-block">
            <img src={profile} style={{ letterSpacing: '0px' }} alt="profile" />
            <p
              style={{
                color: 'rgb(255,255,255)',
                textAlign: 'left',
                marginBottom: '0px',
                marginLeft: '10px',
                fontFamily: '"Inter"',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '138.28%',
                marginRight: '0px',
                paddingRight: 0,
              }}
            >
              Personal manager 24/7
            </p>
          </div>
        </div>
        <div className=" mx-auto text-center">
          <button
            className="btn btn-secondary my-5 mx-2"
            id="bottomButton"
            type="button"
            onClick={() => setSignUpModalOpen(true)}
          >
            Sign Up &amp; Start Earning
          </button>
        </div>
      </div>
    </>
  );
}

export default Content;
